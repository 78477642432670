import './i18n';
import '../components/layout.css';

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ParallaxProvider } from 'react-scroll-parallax';
import { Script } from "gatsby";

import {
  HeroBannerSection,
  PropertiesSection,
  LoremSection,
  FractionalSection,
  BenefitsSection,
  HowSection,
  WhyAncanaSection
} from "../components/sectionsV3";
import ContactSection from "../components/sectionsV3/ContactSection";
import FaqSection from "../components/sectionsV3/FaqSection";
import Footer from "../components/sectionsV3/Footer";
import GallerySection from "../components/sectionsV3/GallerySection";
import MentionsSection from "../components/sectionsV3/MentionsSection";
import NavBar from "../components/sectionsV3/NavBar";
import { scrollToSection } from "../misc/utils";
import { Helmet } from "react-helmet";
import useObserveRef from "../hooks/useObserveRef";
import { adminApi } from "../api/ancanaAPI";
import ContactModal from "../components/ContactModal";


const Container = styled.div`
  overflow-x: hidden;
  // background-color: #FDFBF7;

  .ancana-logo {
    width: 138px;
  }
`;

const mockedListings = [
  {"id": 20,
    "name": "Vista al Río 3",
    "slug": "vista-al-rio-3",
    "lat": "19.154279",
    "lng": "-100.056441",
    "address": "Club de Golf Rancho Avándaro, Valle de Bravo, Mexico, Mexico",
    "type": "unit",
    "published": true,
    "status": "pre_sale",
    "sort_index": 2,
    "amenities": [
      32,
      35,
      36,
      7,
      11,
      45,
      14,
      15,
      18,
      19,
      52,
      53,
      20,
      23,
      51,
      28,
      30,
      31,
      59
    ],
    "images": [
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/d43abc31-34f0-4560-a78f-a1ad19051729_1/191c798c55ef426a2c5955333dbd031d.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/e6e053d3-c049-4e58-905e-217cf25b607c_2/bf6980b4d7824f19928a31ecebd01d24.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/8c0c8e3c-8f43-4b92-8d82-353f692ad55d_1/f0cb43af49089b3cf8fe16066227aaa6.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/770b7826-aa81-46fc-bfa4-9c9387212722_1/48f01a227543f66ad57ff248a2b67da0.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/307b7ea6-af68-4b8b-9020-dbecf1478696_1/668eeeaba489761a572011ba5e4c9451.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/065fb997-3a1f-484e-8b26-6e19f454cacf_1/075739719d2f4c49764f070396b7329c.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/f73c0f73-724c-4e91-8fc9-89982331e289_1/de1afbc21307f86967715e4fae88e976.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/91c8c56e-b1fa-4a2e-bb21-52074b22f4f2_1/005c8b14a7cf547fcb4447da7c1ab504.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/c66a7dde-09ae-49bd-9025-65561951aa1f_1/df3dcd782efa0271f424cca97cb27a6d.webp"
    ],
    "files": [
      {
        "id": 48,
        "file": "https://api-v1.s3.amazonaws.com/files/Vistal_al_Rio_Casa_3_Ancana_Brochure.pdf",
        "name": ""
      }
    ],
    "location": 2,
    "price": {
      "amount": undefined,
      "currency": "MXN"
    },
    "characteristics": {
      "square_meters": 395.0,
      "bedrooms": 4.0,
      "bathrooms": 4.5,
      "fractions": 4,
      "weeks_available": 12
    },
    "description_en": "In the forests of Valle de Bravo, Vista al Rio is hidden between high trees and sits overlooking a winding creek. The development is composed of eight houses arranged on an inclined hill, each facing spectacular views of the forest.\n\nEach house is fully furnished and ranges from 400 to 700 m2, including your very own parking space. The houses are 4 bedrooms and 4 bathrooms, geometrically positioned to face the creek. The sophisticated design presents elegant stonework of granite, marble, and limestone in combination with wooden features elevating your forest lifestyle.\nTwo levels of cozy living spaces to relax to the sounds of the forest.\n\nCapture a wide landscape view from your open living, dining, and kitchen areas. Step out to a large outdoor deck, enjoy the sun on your lounge chairs, relax in your private pool, and embrace the surrounding tranquillity.\n\nTake a walk to Rancho Avándaro, a private development, to enjoy various activities for all ages such as golf, tennis, swimming, kayaking, biking, and more. The clubhouse offers a restaurant, gym, playroom, and green areas ideally to enjoy nature pristinely.",
    "description_es": "En los bosques de Valle de Bravo, Vista al Rio está escondido entre árboles altos y se asienta con vista a un arroyo sinuoso. El desarrollo se compone de 8 casas sobre una colina inclinada, cada una con vistas espectaculares al bosque.\n\nCada casa está completamente amueblada y varía entre los 400 y 700 m2. Las 4 recámaras, cada una con baño, están posicionadas geométricamente de cara al arroyo. El diseño sofisticado presenta un elegante trabajo en piedra de granito, piedra caliza y mármol en combinación con elementos de madera que elevan su estilo forestal.\n\nEsta propiedad ofrece 2 niveles de espacios acogedores para relajarse acompañado de los sonidos del bosque.\n\nDisfruta de una amplia vista del paisaje forestal desde las abiertas áreas de estar, el comedor y la cocina. Sal a una gran terraza al aire libre y siente la calidez del sol en los sillones, relájate en la piscina privada y disfruta de la tranquilidad que lo rodea.",
    "unit": 19,
    "design": null,
    "development": null,
    "cover_image": "https://api-v1.s3.amazonaws.com/CACHE/images/images/d43abc31-34f0-4560-a78f-a1ad19051729_1/fb5d4b1ffd2c2774e729a6cf5482475b.webp",
    "description": "In the forests of Valle de Bravo, Vista al Rio is hidden between high trees and sits overlooking a winding creek. The development is composed of eight houses arranged on an inclined hill, each facing spectacular views of the forest.\n\nEach house is fully furnished and ranges from 400 to 700 m2, including your very own parking space. The houses are 4 bedrooms and 4 bathrooms, geometrically positioned to face the creek. The sophisticated design presents elegant stonework of granite, marble, and limestone in combination with wooden features elevating your forest lifestyle.\nTwo levels of cozy living spaces to relax to the sounds of the forest.\n\nCapture a wide landscape view from your open living, dining, and kitchen areas. Step out to a large outdoor deck, enjoy the sun on your lounge chairs, relax in your private pool, and embrace the surrounding tranquillity.\n\nTake a walk to Rancho Avándaro, a private development, to enjoy various activities for all ages such as golf, tennis, swimming, kayaking, biking, and more. The clubhouse offers a restaurant, gym, playroom, and green areas ideally to enjoy nature pristinely.",
    "tagline": "Beach Paradise",
    "benefits": [
      "Share maintenance and management costs with other co-owners, paying only $393 USD instead of the full $3,144 USD.",
      "Enjoy up to 6 guaranteed weeks a year in your corner of paradise. Opportunity to rent unused weeks, gaining additional return on investment while sharing your luxury with others.",
      "Exchange with over 15,000 luxury properties in more than 120 countries.",
      "Fully managed, furnished, and equipped property for your convenience.",
      "Guarantee of a well-maintained property and high-quality services."
    ],
    "usage_modes": [
      "rent",
      "use",
      "swap",
      "third_home_swap"
    ]
  },
];

const IndexPage = ({ pageContext }) => {
  const [listings, setListings] = useState(mockedListings);
  const containerRef = useRef(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [alreadyPopedup, setAlreadyPopedup] = useState(false);
  const alreadyPopedupRef = useRef(alreadyPopedup);

  const openPopup = () => {
    if(!alreadyPopedupRef.current) {
      setPopupVisible(true);
      // alreadyPopedupRef.current = true;
      setAlreadyPopedup(true);
    }
  }

  const { observedRef } = useObserveRef(alreadyPopedup, openPopup);

  const scrollTo = (name, offset = 0, cancelPopup = true) => {
    if (cancelPopup) setAlreadyPopedup(true);
    scrollToSection(name, containerRef, offset);
  }

  useEffect(() => {
    adminApi.get('listings/previews/?page_size=12&published=true&location_slug=valle-de-bravo').then(res => {
      setListings(res.data.results)
    });
    const timeout = setTimeout(() => openPopup(), 15000);
    return () => {
      clearTimeout(timeout);
    }
  }, []);

  useEffect(() => {
    alreadyPopedupRef.current = alreadyPopedup;
  }, [alreadyPopedup]);

  return (
    <ParallaxProvider>
      <Container ref={containerRef}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Ancana - Valle de Bravo</title>
          <meta name={`robots`} content={`noindex, nofollow`} />

          <Script id="smhng">
            {`
            console.log('hello world!')
          `}
          </Script>
        </Helmet>
        <NavBar scrollTo={scrollTo} />

        <div id="hero">
          <HeroBannerSection scrollTo={scrollTo} />
        </div>
        <div id="property">
          <PropertiesSection listings={listings} scrollTo={scrollTo} />
        </div>
        <LoremSection />
        <div id="fractional">
          <FractionalSection />
        </div>
        <BenefitsSection />
        <div ref={observedRef}>
          <HowSection />
        </div>
        <WhyAncanaSection />
        <div id="contact">
          <ContactSection />
        </div>

        <GallerySection houses={listings} />
        <MentionsSection />

        <FaqSection />

        <Footer scrollTo={(name) => scrollToSection(name, containerRef)} />
      </Container>
      <ContactModal
        visible={popupVisible}
        handleClose={() => setPopupVisible(false)}
      />
    </ParallaxProvider>
  );
}

export default IndexPage;
