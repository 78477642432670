import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

import Fraction from '../../images/icons/fraction.inline.svg';
import HouseExpenses from '../../images/icons/house-expenses.inline.svg';
import Keys from '../../images/icons/keys.inline.svg';
import Coconut from '../../images/icons/coconut.inline.svg';
import H from "../text/H";
import P from "../text/P";
import { breakPoints } from "../../misc/consts";

const Container = styled.div`
  position: relative;

  .bg-img {
    width: 0;
    max-height: 500px;

    ${breakPoints.tablet} {
      width: 85%;
      left: -15%;
    }
    ${breakPoints.desktop} {
      width: 62%;
      left: 0;
    }
    ${breakPoints.largeDesktop} {
      width: 60%;
    }
  }

  .cards {
    gap: 20px;
    position: relative;
    right: 0;
    width: 100%;

    ${breakPoints.tablet} {
      position: absolute;
      width: 65%;
    }
    ${breakPoints.desktop} {
      width: 60%;
    }
    ${breakPoints.largeDesktop} {
      width: 45%;
    }
  }

  ${breakPoints.desktop} {

  }
`;

function BenefitsSection() {
  return (
    <Container className="center white-bg">
      <div className="marginal">
      <H className="fs-5 center-self">Beneficios</H>
      <P className="fs-3 mb-lg center-self center-text">Comprar una fracción es mucho más que solo ser dueño</P>
      <div className="h-stack" style={{ position: 'relative', alignItems: 'center' }}>
        <StaticImage
          src="../../images/corporate/guy-sitting.png"
          alt="guy sitting"
          quality={100}
          className="bg-img"
        />
        <div className="cards">
          <div className="shadow-1 pd-lg h-stack white-bg">
            <Fraction className="mr-sm" />
            <div>
              <H className="fs-3">Comprar solo lo que utlizas</H>
              <P className="fs-2">
                No utilizas tu casa vacacional todo el año, ¿por qué comprarla completa si estará vacía 3/4 del año.
              </P>
            </div>
          </div>
          <div className="shadow-1 pd-lg h-stack white-bg">
            <HouseExpenses className="mr-sm" />
            <div>
              <H className="fs-3">Divide los gastos</H>
              <P className="fs-2">
                En lugar de soportar el 100% de los gastos, Ancana divide los gastos entre todos los co-propietarios.
              </P>
            </div>
          </div>
          <div className="shadow-1 pd-lg h-stack white-bg">
            <Coconut className="mr-sm" />
            <div>
              <H className="fs-3">Tranquilidad</H>
              <P className="fs-2">
                Ancana se encarga de todo: desde interiorismo hasta mantenimiento, para que tu solo te enfoques en disfrutar tu viaje.
              </P>
            </div>
          </div>
          <div className="shadow-1 pd-lg h-stack white-bg">
            <Keys className="mr-sm" />
            <div>
              <H className="fs-3">Reservas flexibles</H>
              <P className="fs-2">
                Agenda tus visitas con 2 días de anticipación o hasta con 2 años. Tu casa siempre estará disponible.
              </P>
            </div>
          </div>
        </div>
      </div>
      </div>
    </Container>
  )
}

export default BenefitsSection;
