import React, { useState } from "react";
import styled from 'styled-components';
import H from "../text/H";
import { ancanaPalette, breakPoints, corporatePalette } from "../../misc/consts";
import P from "../text/P";
import useGoogleSheets from "use-google-sheets";
import Caret from "../../images/icons/playful/caret-down.inline.svg";


const Container = styled.div`
`;

const Faq = styled.div`
  .question {
    background-color: ${corporatePalette.blue};
    color: ${corporatePalette.almostWhite};
    padding: 0 24px;
    justify-content: center;
    font-weight: 600;
    height: 65px;
    margin-bottom: 12px;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 2px 7px rgba(0,0,0,.25);

    font-size: 16px;
    padding-right: 64px;
    ${breakPoints.desktop} {
      font-size: 20px;
      padding-right: 32px;
    }
  }

  .caret {
    position: absolute;
    right: 32px;
    top: 26px;
    font-size: 50px;
    font-weight: 500;
    transform: rotate(${({ opened }) => opened ? '-180deg' : '0'});
    transition: transform 0.25s ease-out;

    rect {
      fill: ${corporatePalette.almostWhite};
    }
  }

  .answer {
    padding: 0 18px;
    background-color: white;
    max-height: ${({ opened }) => opened ? '125px' : '0'};
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }
`;

function FaqSection() {
  const { data, loading, error } = useGoogleSheets({
    apiKey: 'AIzaSyCU8Ntf16o_cDbmsZrMI7S-2wisxlLNJyw',
    sheetId: '1gLNenLdnnDwWQCLhIkm5YNFmYa6bfc_FCygAdEOJf4E',
    sheetsNames: ['valle1'],
  });
  const [openedAnswers, setOpenedAnswers] = useState([]);

  const handleFaqClick = (idx) => {
    if (openedAnswers.includes(idx)) {
      setOpenedAnswers(openedAnswers.filter(a => a !== idx));
    } else {
      setOpenedAnswers([...openedAnswers, idx]);
    }
  }

  return (
    <Container className="marginal">
      <div className="h-stack gap-xl">
        <div className="flx1 h-center">
          <H className="fs-9 mb-md v-underline">
            Preguntas frecuentes
          </H>
          <P className="mb-md">
            Estamos para ayudarte. Si tienes más preguntas
            puedes ponerte en contacto al siguiente correo:
          </P>
          <P>
            <a
              href="mailto:info@ancana.co?subject=Quiero recibir más información sobre Ancana"
              style={{ textDecoration: 'none', color: ancanaPalette.text.primary }}
            ><b>hola@ancana.co</b></a>
          </P>
        </div>
        <div className="flx2">
          <Faq opened={openedAnswers.includes(0)} onClick={() => handleFaqClick(0)}>
            <div className="question">
              {data[0]?.data[0]['Question']}
              <Caret className="caret" />
            </div>
            <div className="answer">
              <P className="mt-sm mb-lg">{data[0]?.data[0]['Answer']}</P>
            </div>
          </Faq>
          <Faq opened={openedAnswers.includes(1)} onClick={() => handleFaqClick(1)}>
            <div className="question">
              {data[0]?.data[1]['Question']}
              <Caret className="caret" />
            </div>
            <div className="answer">
              <P className="mt-sm mb-lg">{data[0]?.data[1]['Answer']}</P>
            </div>
          </Faq>
          <Faq opened={openedAnswers.includes(2)} onClick={() => handleFaqClick(2)}>
            <div className="question">
              {data[0]?.data[2]['Question']}
              <Caret className="caret" />
            </div>
            <div className="answer">
              <P className="mt-sm mb-lg">{data[0]?.data[2]['Answer']}</P>
            </div>
          </Faq>
          <Faq opened={openedAnswers.includes(3)} onClick={() => handleFaqClick(3)}>
            <div className="question">
              {data[0]?.data[3]['Question']}
              <Caret className="caret" />
            </div>
            <div className="answer">
              <P className="mt-sm mb-lg">{data[0]?.data[3]['Answer']}</P>
            </div>
          </Faq>
          <Faq opened={openedAnswers.includes(4)} onClick={() => handleFaqClick(4)}>
            <div className="question">
              {data[0]?.data[4]['Question']}
              <Caret className="caret" />
            </div>
            <div className="answer">
              <P className="mt-sm mb-lg">{data[0]?.data[4]['Answer']}</P>
            </div>
          </Faq>
        </div>
      </div>
    </Container>
  )
}

export default FaqSection;
