import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MenuIcon from '../../images/collage/menu.inline.svg';
import MenuBlueIcon from '../../images/icons/menu-blue.inline.svg';
import AncanaIcon from '../../images/icons/ancana.inline.svg';

import { bravoPalette, breakPoints } from "../../misc/consts";
import H from "../text/H";
import P from "../text/P";


const Container = styled.div`
  background-color: transparent;
  width: 100%;
  height: 100px;
  justify-content: flex-end;
  z-index: 3;
  flex-direction: row;
  position: absolute;

  padding: 32px 32px 4px 32px;
  ${breakPoints.desktop} {
    padding: 56px 45px 6px 45px;
  }

  .logo {
    transform: scale(1.25) translate(5%, 15%);
    ${breakPoints.desktop} {
      transform: scale(1.5) translateX(25%);
    }
  }

  .menu-icon {
    display: flex;

    ${breakPoints.desktop} {
      display: none;
    }
  }

  .slider {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 333;
  }
`;

/*
const Menu = styled.div`
  flex-direction: row;
  display: none;

  ${breakPoints.desktop} {
    display: flex;
  }

  .menu-item {
    color: ${bravoPalette.wine};
    font-weight: bold;
    font-size: 16px;
    margin: 0 25px;
    cursor: pointer;
  }
`;
*/

const MobileMenu = styled.div`
  background: ${bravoPalette.almostWhite};
  color: ${bravoPalette.blue};
  padding: 32px;
  width: 100vw;
  max-width: 400px;
  height: 100vh;
  box-shadow: 0 2px 35px rgba(0,0,0,.5);

  transform: translateX(${({ showMenu }) => showMenu ? '0px' : '100vw'});
  transition: transform 0.5s ease;

  position: fixed;
  right: 0;
  top: 0;
  z-index: 333;

  .menu-item {
    font-weight: bold;
    font-size: 24px;
    cursor: pointer;
    align-self: flex-end;
  }
`;

const MenuOverlay = styled.div`
  z-index: 332;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  visibility: ${({ showMenu }) => showMenu ? 'visible' : 'hidden'};
  opacity: ${({ showMenu }) => showMenu ? 1 : 0};
  transition: visibility 0.15s, opacity 0.5s linear;

  background-color: rgba(0,0,0,.5);
  backdrop-filter: blur(3px);
`;

function NavBar({ scrollTo }) {
  const [showMenu, setShowMenu] = useState(false);
  const handleMenuClick = () => setShowMenu(!showMenu);

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showMenu]);

  return (
    <Container>
      <MenuBlueIcon className="menu-icon" onClick={handleMenuClick} />
      <MenuOverlay showMenu={showMenu} onClick={handleMenuClick} />
      <MobileMenu className="mobile-and-tablet" showMenu={showMenu}>
        <AncanaIcon className="logo" />
        <MenuIcon style={{ alignSelf: 'flex-end', marginTop: -15, marginBottom: 12 }} onClick={handleMenuClick} />
        <div className="menu-item mt-xl" onClick={() => {handleMenuClick();scrollTo('hero', 0, false)}}>Inicio</div>
        <div className="menu-item mt-xl" onClick={() => {handleMenuClick();scrollTo('property', 0, false)}}>Propiedades</div>
        <div className="menu-item mt-xl mb-xxl" onClick={() => {handleMenuClick();scrollTo('fractional', 0, false)}}>¿Cómo funciona?</div>
        <div className="flx1" />
        <div>
          <div className="fw-underline mt-xxl mb-lg" />
          <H className="mb-sm left-self">Contacto</H>
          <P className="left-self">hola@ancana.co</P>
          <P className="left-self">55-6565-5555</P>
          <div className="fw-underline mt-xxl mb-md" />
          <P className="left-self">Políticas de privacidad</P>
        </div>
      </MobileMenu>

      {/*
      <Menu className="center">
        <B className="mt-sm mr-xl">Inicio</B>
        <B className="mt-sm mr-xl">Propiedades</B>
        <B className="mt-sm mr-xl">¿Cómo funciona?</B>
        <Button>Pedir información</Button>
      </Menu>
      */}
    </Container>
  )
}

export default NavBar;
