import { useCallback, useRef } from "react";

function useObserveRef(blocked: boolean, action: any) {
  const observer = useRef<IntersectionObserver>();
  const observedRef = useCallback(  // (*)
    (node: any) => {
      if (blocked) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          action();
        }
      });
      if (node) observer.current.observe(node);
    },
    [blocked]
  );

  return {
    observedRef,
  }
}

export default useObserveRef;
