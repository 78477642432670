import React, { useState, useCallback, useRef } from "react";
import styled from 'styled-components';
import { ancanaPalette, breakPoints, debounce } from "../misc/consts";
import CaretLeft from '../images/icons/caret-left.inline.svg';
import CaretRight from '../images/icons/caret-right.inline.svg';

const Container = styled.div`
  height: 100%;
  position: relative;
  align-items: center;

  .scroller {
    height: 100%;
    width: 100%;
    flex-direction: row;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;

    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .img-slide {
    scroll-snap-align: center;

    height: 100%;
    min-width: 100%;
    flex: 1;
    background-size: cover;
  }

  .bulls {
    flex-direction: row;
    position: absolute;
    bottom: -30px;
    z-index: 113;
    font-size: 22px;
  }
  .nav {
    cursor: pointer;
    width: 28px;
    height: 40px;
    position: absolute;
    top: calc(50% - 20px);
    display: none;
    ${breakPoints.largeTablet} {
      display: flex;
    }
    .nav-son {
      height: 100%;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }
  }
  .right {
    right: 8px;
  }
  .left {
    left: 8px
  }
`;

const Bull = styled.div`
  padding: 0 2px;
  margin: 0 1px;
  // cursor: pointer;
  color: ${({ selected }) => selected ? ancanaPalette.secondary.main : '#c4c4c4'};
`;

function InstaCarousel({ images, className, showHandles = false }) {
  const [currentIdx, setCurrentIdx] = useState(0);
  const scrollRef = useRef(null);

  const handleScroll = useCallback((e) => {
    const slideWidth = (e.target.scrollWidth - e.target.offsetWidth) / (images.length -1);
    const position = e.target.scrollLeft / slideWidth;
    setCurrentIdx(Math.floor(position + 0.5));
  }, [images]);

  const handleClickBull = useCallback((idx) => {
    if (CSS.supports('scroll-behavior: smooth')) {
      scrollRef.current?.children[idx].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    } else {
      // seems like smooth-scroll Polyfill doesn't work in combination with scroll-snap-type,
      // so we use scroll without smooth behavior, TODO: find a fix for this
      scrollRef.current?.children[idx].scrollIntoView({ block: 'nearest', inline: 'start' });
    }
    setCurrentIdx(idx);
  }, []);

  const handleNext = () => {
    console.log('handleNext', currentIdx + 1 < images.length, currentIdx + 1, images.length);
    if (currentIdx + 1 < images.length) {
      handleClickBull(currentIdx + 1);
    }
  }

  const handlePrev = () => {
    if (currentIdx - 1 >= 0) {
      handleClickBull(currentIdx - 1);
    }
  }

  return (
    <Container className={className}>
      <div className="scroller" onScroll={debounce(handleScroll)} ref={scrollRef}>
        {images.map(image => (
          <div
            className="img-slide"
            style={{ background: `url(${image}) center center / cover no-repeat` }}
          />
        ))}
      </div>
      <div className="bulls">
        {images.map((item, idx) => (
          <Bull className="bull" selected={idx === currentIdx} onClick={() => handleClickBull(idx)}>•</Bull>
        ))}
      </div>
      {showHandles && (
        <>
          <div className="nav left shadow-partner" onClick={handlePrev}>
            <div className="nav-son shadow-1 corp-shadow-sm-rev">
              <CaretLeft />
            </div>
          </div>
          <div className="nav right shadow-partner" onClick={handleNext}>
            <div className="nav-son shadow-1 corp-shadow-sm">
              <CaretRight />
            </div>
          </div>
        </>
      )}
    </Container>
  )
}

export default InstaCarousel;
