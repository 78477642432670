import React from "react";
import styled from 'styled-components';
import { bravoPalette, breakPoints } from "../../misc/consts";


const Container = styled.div`
  font-size: ${({ size }) => size ? size : '14'}px;
  font-weight: ${({ weight }) => weight};
  color: ${({ color }) => color};

  ${breakPoints.desktop} {
    font-size: ${({ size }) => size ? size : '18'}px;
  }
`;

function B(props) {
  return (
    <Container
      color={props.color || bravoPalette.blue}
      size={props.size}
      style={props.style}
      weight={props.weight || 800}
      className={props.className}
      onClick={props.onClick || null}
    >
      {props.children}
    </Container>
  )
}

export default B;
