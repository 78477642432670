import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import H from "../text/H";
import P from "../text/P";
import { breakPoints } from "../../misc/consts";
import CaretLeft from "../../images/icons/caret-left.inline.svg";
import CaretRight from "../../images/icons/caret-right.inline.svg";


const Container = styled.div`
  position: relative;

  .gallery-cont {
    position: relative;
    cursor: pointer;
    height: 50vh;

    ${breakPoints.desktop} {
      height: 75vh;
    }
  }

  .caret-cont {
    position: absolute;
    top: calc(50% - 32px);
    cursor: pointer;
  }
  .caret-left {
    left: -16px;
  }
  .caret-right {
    right: -16px;
  }
`;

function GallerySection({ houses }) {
  const [isOpen, setIsOpen] = useState(false);
  const [houseIndex, setHouseIndex] = useState(0);
  const [photoIndex, setPhotoIndex] = useState(0);

  const switchHouse = (e, next = true) => {
    e.stopPropagation();
    const nextIdx = next ? houseIndex + 1 : houseIndex - 1;
    setHouseIndex(Math.abs(nextIdx % houses.length));
  }

  if (houses.length === 0) return null;

  return (
    <Container className="marginal white-bg">
      <H className="center fs-5 pd-lg">GALERÍA DE FOTOS</H>
      <P className="center-self mb-lg">Casa: {houses[houseIndex].name}</P>

      <div
        className="h-stack-strict gap-xs gallery-cont"
        onClick={() => setIsOpen(true)}
      >
        <div style={{ background: `url(${houses[houseIndex].images[5]}) center center / cover no-repeat`, flex: 1 }} />
        <div className="flx2 gap-xs">
          <div style={{ background: `url(${houses[houseIndex].images[0]}) center center / cover no-repeat`, flex: 1 }} />
          <div className="h-stack-strict gap-xs flx1">
            <div className="flx1" style={{ background: `url(${houses[houseIndex].images[2]}) center center / cover no-repeat`}} />
            <div className="flx1" style={{ background: `url(${houses[houseIndex].images[3]}) center center / cover no-repeat`}} />
          </div>
        </div>
        <div className="caret-cont caret-left z-idx-3">
          <div className="shadow-1" style={{ padding: '18px 12px', backgroundColor: 'rgba(255,255,255,.75)' }} onClick={(e) => switchHouse(e, false)}>
            <CaretLeft />
          </div>
        </div>
        <div className="caret-cont caret-right z-idx-3">
          <div className="shadow-1" style={{ padding: '18px 12px', backgroundColor: 'rgba(255,255,255,.75)' }} onClick={switchHouse}>
            <CaretRight />
          </div>
        </div>
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={houses[houseIndex].images[photoIndex]}
          nextSrc={houses[houseIndex].images[(photoIndex + 1) % houses[houseIndex].images.length]}
          prevSrc={houses[houseIndex].images[(photoIndex + houses[houseIndex].images.length - 1) % houses[houseIndex].images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + houses[houseIndex].images.length - 1) % houses[houseIndex].images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % houses[houseIndex].images.length)}
        />
      )}
    </Container>
  )
}

export default GallerySection;
