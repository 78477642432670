import React from "react";
import styled from 'styled-components';
import { bravoPalette, breakPoints } from "../../misc/consts";


const Container = styled.p`
  font-size: ${({ size }) => size ? size : '14'}px;
  font-weight: 400;
  color: ${({ color }) => color};
  line-height: 1.5;
  text-align: ${({ justify }) => justify ? 'justify' : 'left'};
  text-justify: inter-word;
  margin: 0;
  // margin-bottom: 16px;

  ${breakPoints.desktop} {
    font-size: ${({ size }) => size ? size : '18'}px;
  }
`;

function P(props) {
  return (
    <Container
      color={props.color || bravoPalette.blue}
      size={props.size}
      style={props.style}
      className={props.className}
      justify={props.justify || false}
    >
      {props.children}
    </Container>
  )
}

export default P;
