import React, { useEffect, useState } from "react";
import "./style.css";

function useDelayUnmount(isMounted, delayTime) {
  const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isMounted && !showDiv) {
      setShowDiv(true);
    } else if (!isMounted && showDiv) {
      timeoutId = setTimeout(() => setShowDiv(false), delayTime); //delay our unmount
    }
    return () => clearTimeout(timeoutId); // cleanup mechanism for effects , the use of setTimeout generate a sideEffect
  }, [isMounted, delayTime, showDiv]);
  return showDiv;
}

const ShowWithAnimation = ({ children, isMounted, delay = 500, containerStyles = {} }) => {
  const showDiv = useDelayUnmount(isMounted, delay);

  console.log('containerStyles', containerStyles);

  const mountedStyle = {
    animation: `inAnimation ${delay}ms`,
    zIndex: 111,
    ...containerStyles
  };
  const unmountedStyle = {
    animation: `outAnimation ${delay}ms`,
    animationFillMode: "forwards",
    zIndex: 111,
    ...containerStyles
  };

  return (
    <>
      {showDiv && (
        <div style={isMounted ? mountedStyle : unmountedStyle}>{children}</div>
      )}
    </>
  );
};

export default ShowWithAnimation;
