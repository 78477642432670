
export const localizePrice = (price, currency, shortLang = 'en') => {
  let convertedPrice = price;
  let convertedCurrency = currency;
  if (shortLang === 'en' && currency === 'MXN') {
    convertedPrice = price / 21;
    convertedCurrency = 'USD';
  }
  //if (shortLang === 'es' && currency === 'USD') {
  //  convertedPrice = price * 21;
  //  convertedCurrency = 'MXN';
  //}
  return `$${Math.ceil(convertedPrice).toLocaleString('en-US')} ${convertedCurrency}`
}


export const localizeArea = (squareMts, shortLang) =>
  `${Math.trunc(shortLang === 'es' ? squareMts : squareMts * 10.7639).toLocaleString('en-US')} ${shortLang === 'es' ? 'm' : 'ft'}²`


export const scrollToSection = (sectionName, containerRef, offset = 0) => {
  console.log('scrollToSection', containerRef)
  const sectionPosition = containerRef.current.children[sectionName].offsetTop - offset;
  window.scrollTo({ top: sectionPosition, behavior: "smooth" });
}
