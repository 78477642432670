import React from "react";
import styled from 'styled-components';
import { ancanaPalette, bravoPalette, breakPoints } from "../misc/consts";
import { useTranslation } from "react-i18next";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css';


const Container = styled.div`
  text-align: left;
  padding: 0 0 12px 0;
  ${breakPoints.desktop} {
    padding: 0 0 22px 0;
  }

  label {
    text-transform: capitalize;
    font-weight: 600;
    margin-left: 2px;
    margin-bottom: 2px;
    font-size: 14px;
  }

  .input {
    font-family: Nuckle, sans-serif;
    padding: 10px 16px;
    border: 1px ${({ hasError }) => hasError ? ancanaPalette.error.main : bravoPalette.blue} solid;
    border-radius: 8px;
    background-color: white;
    outline-color: ${({ hasError }) => hasError ? ancanaPalette.error.main : ancanaPalette.secondary.main};
    font-size: 16px;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-size: 12px;
      color: ${ancanaPalette.lightGrey};
    }
    ::-moz-placeholder { /* Firefox 19+ */
      font-size: 12px;
      color: ${ancanaPalette.lightGrey};
    }
    :-moz-placeholder { /* Firefox 18- */
      font-size: 12px;
      color: ${ancanaPalette.lightGrey};
    }
  }

  .react-tel-input {
    border: ${({ hasError }) => hasError ? ancanaPalette.error.main : ancanaPalette.secondary.main};
  }

  .phone {
    padding-left: 56px;
  }

  .input-error {
    margin-top: 6px;
    margin-bottom: 0;
    color: ${ancanaPalette.error.main};
    font-size: 12px;
  }
`;

function Input({ name, register, error, locale = 'en' }) {
  const { t } = useTranslation();
  const registerProps = register(name);

  const inputType = () => {
    switch (name) {
      case 'email':
        return 'email';
      case 'phone':
        return 'number';
      default:
        return 'input'
    }
  };

  return (
    <Container hasError={!!error?.message}>
      <label className="label">{t(`${name}`)}</label>
      {name === 'phone' ? (
        <PhoneInput
          country={locale === 'es' ? 'mx' : 'us'}
          preferredCountries={['mx','ca','us']}
          inputProps={{
            className: 'input phone',
            ref: registerProps.ref,
            name: registerProps.name,
            onBlur: registerProps.onBlur,
          }}
          onChange={(a, b, c, d) => {
            registerProps.onChange(c);
          }}
          placeholder={'Número de teléfono'}
        />
      ) : (
        <input className="input" placeholder={t(`${name} placeholder`)} {...registerProps} />
      )}
      {!!error?.message && <p className="input-error">{t(error?.message)}</p>}
    </Container>
  )
}

export default Input;
