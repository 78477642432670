import axios from 'axios';

const ancanaAPI  = axios.create({
  // baseURL: 'http://localhost:8000/api/',
  baseURL: 'https://prod.api.ancana.co/api/',
  timeout: 120000,
});

export const adminApi = axios.create({
  baseURL: 'https://api.ancana.co/v1/',
  timeout: 30000,
})

export default ancanaAPI;
