import React from "react";
import styled from 'styled-components';
import H from "../text/H";
import { corporatePalette } from "../../misc/consts";
import ElUniversal from '../../images/corpLogos/eluniversal.inline.svg';
import Remax from '../../images/corpLogos/remax.inline.svg';
import Forbes from '../../images/corpLogos/forbes.inline.svg';
import Forbes2 from '../../images/corpLogos/forbes.png';

import { StaticImage } from "gatsby-plugin-image";
import P from "../text/P";


const Container = styled.div`
  position: relative;
  z-index: 0;
  overflow: hidden;

  .img-cont {
    position: absolute;
    right: 0;
    left: 0;
    overflow: hidden;
    background-color: rgb(42,42,42,.9);
  }

  .bg-img {
    z-index: -2;
    height: 500px;
  }
`;

function MentionsSection() {

  return (
    <Container className="">
      <div className="img-cont">
        <StaticImage
          src="../../images/vista-al-rio-desktop.jpeg"
          alt="vista al rio bg"
          quality={100}
          className="bg-img"
        />
      </div>

      <div className="h-stack gap-xl marginal center tablet-and-up" style={{ zIndex: 2 }}>
        <H className="fs-8 flx2" color={corporatePalette.almostWhite}>
          Menciones y colaboraciones
        </H>
        <ElUniversal className="flx2" />
        <Remax className="flx1" />
        <img className="flx1" src={Forbes2} alt="forbes" />
      </div>

      <div className="mobile-only marginal" style={{ zIndex: 2 }}>
        <H className="fs-10 flx3 mb-md center-text" color={corporatePalette.almostWhite}>
          Menciones y colaboraciones
        </H>
        <div className="h-stack-strict gap-lg center h-center">
          <ElUniversal className="flx2" style={{ width: 125 }} />
          <Remax className="flx1" style={{ width: 75 }} />
          <img className="flx1" src={Forbes2} alt="forbes" style={{ width: 50 }} />
        </div>
      </div>
    </Container>
  )
}

export default MentionsSection;
