import React from "react";
import styled from 'styled-components';
import { bravoPalette, breakPoints } from "../misc/consts";
import Spinner from "../images/icons/spinner.inline.svg";

const Container = styled.div`
  align-items: flex-start;

  .btn {
    padding: ${({ variant }) => variant === 'outlined' ? '12px' : '14px'} 14px;
    background-color: ${({ variant, color }) => variant === 'outlined' ? 'transparent' : color};
    color: ${({ variant, color }) => variant === 'outlined' ? color : 'white'};
    border: ${({ variant, color }) => variant === 'outlined' ? `2px ${color} solid` : '0'};
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    min-width: 80px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      background-color: ${({ color }) => `${color}A4`};
      color: ${({ color }) => color};
    }
  }

  ${breakPoints.desktop} {
    .btn {
      padding: ${({ variant }) => variant === 'outlined' ? '12px' : '14px'} 24px;
      background-color: ${({ variant, color }) => variant === 'outlined' ? 'transparent' : color};
      color: ${({ variant, color }) => variant === 'outlined' ? color : 'white'};
      border: ${({ variant, color }) => variant === 'outlined' ? `2px ${color} solid` : '0'};
      border-radius: 8px;
      cursor: pointer;
      font-size: 16px;
      min-width: 140px;
    }
  }
  .spinner-cont {
    width: 25px;
    position: relative;
    margin: 0 auto;
  }
`;

function Button(props) {
  return (
    <Container
      color={props.color || bravoPalette.blue}
      variant={props.variant || "solid"}
      style={{ ...props.style }}
    >
      <button
        className="btn"
        onClick={props.onClick}
        style={{ ...props.btnStyle }}
        disabled={props.disabled}
      >
        {!!props.loading ? (
          <div className="spinner-cont">
            <Spinner />
          </div>
        ) : (
          props.children
        )}
      </button>
    </Container>
  )
}

export default Button;
